define("apollo/pods/drivers/create/controller", ["exports", "jquery", "apollo/config/environment", "apollo/mixins/persistence-mixin", "apollo/mixins/modal-mixin", "apollo/mixins/transports-mixin"], function (_exports, _jquery, _environment, _persistenceMixin, _modalMixin, _transportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _modalMixin.default, _transportsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    store: Ember.inject.service(),
    companiesCreateController: Ember.inject.controller('companies/create'),
    driverController: Ember.inject.controller('drivers/index'),
    isEditing: false,
    TRANSPORT_TYPES: Ember.computed('', function () {
      return this.get('store').peekAll('transport-type').filter(function (type) {
        return ["TRANSPORT", "EXTERNAL_TRANSPORT_ORDER", "MANUAL_TRANSPORT", "EXTERNAL_MANUAL_TRANSPORT", "DELIVERY", "EXTERNAL_DELIVERY"].includes(type.get('name'));
      });
    }),
    historyLogs: [],
    setHistoryLogs: Ember.observer('showResourcesHistory', function () {
      var auditLog = this.get('model.auditLogs');
      this.set('historyLogs', auditLog);
    }),
    mapImage: Ember.computed(function () {
      return '/assets/images/sample/map.png';
    }).readOnly(),
    countries: Ember.computed(function () {
      return this.store.peekAll('country');
    }),
    hasDirtyDocuments: Ember.computed('model.documents.@each.hasDirtyAttributes', function () {
      if (this.get('model.documents')) {
        return this.get('model.documents').filter(function (f) {
          return f.get('hasDirtyAttributes');
        }).length > 0;
      }
    }),
    isDirty: Ember.computed('model.hasDirtyAttributes', 'hasDirtyDocuments', function () {
      return this.get('model.hasDirtyAttributes') || this.get('hasDirtyDocuments');
    }),
    errors: [],
    showOrderHistory: false,
    showResourcesHistory: false,
    loadingInProgress: false,
    principal: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),
    setTransportType: Ember.observer('principal.transportTypes.firstObject', 'showOrderHistory', function () {
      this.set('transportType', this.get('principal.transportTypes.firstObject'));
    }),
    searchParams: Ember.computed('model', function () {
      return {
        query: '',
        driverId: this.get('model.id'),
        filters: Ember.A(),
        assetType: '',
        className: 'Driver'
      };
    }),
    actions: {
      save: function save() {
        this.set('savingInProgress', true);
        var self = this;
        var driverModel = this.get('model');
        driverModel.validate();

        if (driverModel.get('errors.length')) {
          this.set('savingInProgress', false);
          return;
        }

        driverModel.save().then(function (driver) {
          self.set('errors', []); //bez reloada front gubi edytowane daty ważności

          driver.reload().then(function () {
            driver.set('rodoAccepted', false);
            self.removeDuplicateRecords(driver, 'resourceDates');
            var resourceDatesCopy = driver.get('resourceDates').slice();
            resourceDatesCopy.forEach(function (rd) {
              if (rd !== undefined && !rd.get('id')) {
                driver.get('resourceDates').removeObject(rd);
                rd.unloadRecord();
              }
            });
            var msg = self.get('intl').t('driver.savedMessage', {
              driverName: driverModel.get('name')
            });
            self.get('companiesCreateController').set('successMessage', msg);
            self.get('driverController').set('successMessage', msg);
            window.history.back();
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        }).finally(function () {
          self.set('savingInProgress', false);
        });
      },
      cancel: function cancel() {
        if (this.get('model.hasDirtyAttributes')) {
          this.get('model').rollbackAttributes();
        }

        this.get('model.resourceDates').filter(function (rd) {
          return rd.get('hasDirtyAttributes');
        }).forEach(function (rd) {
          return rd.rollbackAttributes();
        });
        window.history.back();
      },
      disable: function disable() {
        var self = this;
        var driverModel = this.get('model');
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/drivers/' + driverModel.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          driverModel.set('enabled', false);
          var truck = driverModel.get('truck');

          if (truck.get('id')) {
            truck.set('driver', null);
          }

          var msg = self.get('intl').t('driver.deletedMessage', {
            driverName: driverModel.get('name')
          });
          self.get('companiesCreateController').set('successMessage', msg);
          window.history.back();
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      toogleOrderHistoryAndClearFilters: function toogleOrderHistoryAndClearFilters() {
        this.set('showResourcesHistory', false);
        this.set('searchParams.assetType', '');
        this.set('showOrderHistory', !this.get('showOrderHistory'));
        this.get('searchParams.filters').clear();
      },
      toogleResourcesHistoryAndClearFilters: function toogleResourcesHistoryAndClearFilters() {
        this.set('searchParams.assetType', 'AUDIT_LOG');
        this.set('showResourcesHistory', !this.get('showResourcesHistory'));
        this.get('searchParams.filters').clear();
      },
      loadingResourcesHistoryData: function loadingResourcesHistoryData() {
        this.set('showOrderHistory', false);
        this.set('loadingInProgress', !this.get('loadingInProgress'));
      }
    }
  });

  _exports.default = _default;
});