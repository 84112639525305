define("apollo/pods/components/transport/settlements-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      getIntegrations: function getIntegrations(transport) {
        this.getControllingIntegrations(transport);
        this.getExternalCostIntegrations(transport);
      }
    }
  });

  _exports.default = _default;
});