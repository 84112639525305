define("apollo/pods/trailers/create/controller", ["exports", "jquery", "apollo/mixins/persistence-mixin", "apollo/mixins/modal-mixin", "apollo/mixins/transports-mixin", "apollo/config/environment"], function (_exports, _jquery, _persistenceMixin, _modalMixin, _transportsMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _modalMixin.default, _transportsMixin.default, {
    DATES_MAPPING: {
      d: 'days',
      w: 'weeks',
      m: 'months',
      y: 'years'
    },
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    SERVICE_TYPES: Ember.computed('', function () {
      return this.get('store').peekAll('transport-type').filter(function (type) {
        return type.get('name') === "SERVICE";
      });
    }),
    TRANSPORT_TYPES: Ember.computed('', function () {
      return this.get('store').peekAll('transport-type').filter(function (type) {
        return ["TRANSPORT", "EXTERNAL_TRANSPORT_ORDER", "MANUAL_TRANSPORT", "EXTERNAL_MANUAL_TRANSPORT", "DELIVERY", "EXTERNAL_DELIVERY"].includes(type.get('name'));
      });
    }),
    trailerIndexController: Ember.inject.controller('trailers/index'),
    truckIndexController: Ember.inject.controller('trucks/index'),
    containerIndexController: Ember.inject.controller('containers/index'),
    companiesCreateController: Ember.inject.controller('companies/create'),
    isEditing: false,
    isDirty: Ember.computed('model.vehicle.hasDirtyAttributes', 'model.company.owner.hasDirtyAttributes', 'model.person.driver.hasDirtyAttributes', function () {
      return this.get('model.vehicle.hasDirtyAttributes') || this.get('model.company.owner.hasDirtyAttributes') || this.get('model.person.driver.hasDirtyAttributes');
    }),
    mapImage: Ember.computed(function () {
      return '/assets/images/sample/map.png';
    }).readOnly(),
    showServiceHistory: false,
    showOrderHistory: false,
    showResourcesHistory: false,
    loadingInProgress: false,
    historyLogs: [],
    setHistoryLogs: Ember.observer('showResourcesHistory', function () {
      var auditLog = this.get('model.vehicle.auditLogs');
      this.set('historyLogs', auditLog);
    }),
    principal: Ember.computed(function () {
      return this.get('store').peekRecord('company', this.get('cookies').read('principalId'));
    }),
    searchParams: Ember.computed('model', 'showOrderHistory', 'showServiceHistory', function () {
      var transportTypeIds = (this.get('showOrderHistory') ? this.get('TRANSPORT_TYPES') : this.get('SERVICE_TYPES')).getEach('id');

      switch (this.get('model.vehicle.vehicleType')) {
        case 'TRUCK':
          return {
            transportTypesIds: transportTypeIds,
            query: '',
            truckId: this.get('model.vehicle.id'),
            filters: Ember.A(),
            assetType: '',
            className: 'Truck'
          };

        case 'TRAILER':
          return {
            transportTypesIds: transportTypeIds,
            query: '',
            trailerId: this.get('model.vehicle.id'),
            filters: Ember.A(),
            assetType: '',
            className: 'Trailer'
          };

        case 'CONTAINER':
          return {
            transportTypesIds: transportTypeIds,
            query: '',
            containerId: this.get('model.vehicle.id'),
            filters: Ember.A(),
            assetType: '',
            className: 'Container'
          };

        default:
          return {
            transportTypesIds: transportTypeIds,
            query: '',
            filters: Ember.A()
          };
      }
    }),
    formName: Ember.computed('model.vehicle.vehicleType', function () {
      var vehicleType = this.get('model.vehicle.vehicleType');
      return vehicleType ? "vehicles/".concat(vehicleType.toLowerCase(), "-form") : null;
    }),
    findEditedDateExceededInterval: function findEditedDateExceededInterval(vehicleModel) {
      var _this = this;

      var datesExceededInterval = [];
      var editedDates = vehicleModel.get('resourceDates').filter(function (rd) {
        return rd.modelChanges().date;
      });
      editedDates.forEach(function (rd) {
        var interval = rd.get('controlledDateType.updateDateIntervalWarning');
        var oldValue = rd.modelChanges().date[0];
        var newValue = rd.modelChanges().date[1];

        if (!interval || !oldValue || !newValue) {
          return;
        }

        var oldMoment = moment(oldValue);
        var newMoment = moment(newValue);
        var intervalValue = interval.slice(0, -1);
        var intervalUnit = interval.slice(-1);

        if (newMoment.diff(oldMoment, _this.DATES_MAPPING[intervalUnit]) >= intervalValue) {
          datesExceededInterval.pushObject(rd);
        }
      });
      return datesExceededInterval;
    },
    findConfirmMessage: function findConfirmMessage(resourceDates) {
      var _this2 = this;

      var basicWarning = this.get('intl').t('resources.updateDateExceedIntervalWarning.basic');
      var details = [];
      var confirm = this.get('intl').t('resources.updateDateExceedIntervalWarning.confirm');
      resourceDates.forEach(function (rd) {
        var interval = rd.get('controlledDateType.updateDateIntervalWarning');
        var intervalValue = interval.slice(0, -1);
        var intervalUnit = interval.slice(-1);

        var intervalString = _this2.get('intl').t("common.interval.".concat(intervalUnit), {
          count: intervalValue
        });

        var resourceDateLabel = rd.get('controlledDateType.label');

        var message = _this2.get('intl').t('resources.updateDateExceedIntervalWarning.details', {
          controlledDateType: resourceDateLabel,
          interval: intervalString
        });

        details.pushObject(message);
      });
      return "".concat(basicWarning, "\n").concat(details.join("\n"), "\n").concat(confirm);
    },
    actions: {
      save: function save() {
        this.set('savingInProgress', true);
        var self = this;
        var vehicleModel = this.get('model.vehicle');
        vehicleModel.validate();

        if (vehicleModel.get('errors.length') || vehicleModel.get('owner.errors.length') || vehicleModel.get('driver.errors.length')) {
          this.set('savingInProgress', false);
          return;
        }

        var editedDatesExceededInterval = self.findEditedDateExceededInterval(vehicleModel);

        if (editedDatesExceededInterval.get('length') !== 0 && !confirm(self.findConfirmMessage(editedDatesExceededInterval))) {
          return;
        }

        vehicleModel.save().then(function (vehicle) {
          self.set('errors', []); //bez reloada front gubi edytowane daty ważności

          vehicle.reload().then(function () {
            self.removeDuplicateRecords(vehicle, 'resourceDates');
            var resourceDatesCopy = vehicle.get('resourceDates').slice();
            resourceDatesCopy.forEach(function (rd) {
              if (rd !== undefined && !rd.get('id')) {
                vehicle.get('resourceDates').removeObject(rd);
                rd.unloadRecord();
              }
            });
            var msg = self.get('intl').t('vehicle.savedMessage', {
              vehicleRegistrationNumber: vehicle.get('registrationNumber')
            });
            self.get('companiesCreateController').set('successMessage', msg);

            if (vehicleModel.get('owner.isExternal')) {
              self.transitionToRoute('companies.edit', vehicleModel.get('owner.id'));
            } else if (vehicleModel.get('vehicleType') === 'TRAILER') {
              self.get('trailerIndexController').set('successMessage', msg);
              window.history.back();
            } else if (vehicleModel.get('vehicleType') === 'TRUCK') {
              self.get('truckIndexController').set('successMessage', msg);
              window.history.back();
            } else if (vehicleModel.get('vehicleType') === 'CONTAINER') {
              self.get('containerIndexController').set('successMessage', msg);
              window.history.back();
            }
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        }).finally(function () {
          self.set('savingInProgress', false);
        });
      },
      cancel: function cancel() {
        this.get('model.vehicle').rollbackAttributes();
        window.history.back();
      },
      disable: function disable() {
        var _this3 = this;

        var self = this;
        var vehicleModel = this.get('model.vehicle');

        if (vehicleModel.get('truck.id')) {
          vehicleModel.set('truck.trailer', null);
        }

        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/' + vehicleModel.get('vehicleType').toLowerCase() + 's/' + vehicleModel.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          vehicleModel.set('enabled', false);
          var msg = self.get('intl').t('vehicle.deletedMessage', {
            vehicleRegistrationNumber: _this3.get('model.vehicle.registrationNumber')
          });

          if (vehicleModel.get('owner.isExternal')) {
            self.transitionToRoute('companies.edit', vehicleModel.get('owner.id'));
          } else if (vehicleModel.get('vehicleType') === 'TRAILER') {
            self.get('trailerIndexController').set('successMessage', msg);
            self.transitionToRoute('trailers.index');
          } else if (vehicleModel.get('vehicleType') === 'TRUCK') {
            self.get('truckIndexController').set('successMessage', msg);
            self.transitionToRoute('trucks.index');
          } else if (vehicleModel.get('vehicleType') === 'CONTAINER') {
            self.get('containerIndexController').set('successMessage', msg);
            self.transitionToRoute('containers.index');
          }
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      toogleServiceHistoryAndClearFilters: function toogleServiceHistoryAndClearFilters() {
        this.set('showOrderHistory', false);
        this.set('showResourcesHistory', false);
        this.set('searchParams.assetType', '');
        this.set('showServiceHistory', !this.get('showServiceHistory'));
        this.get('searchParams.filters').clear();
        (0, _jquery.default)(function () {
          (0, _jquery.default)(document).scrollTop((0, _jquery.default)('#service-and-order-history').offset().top);
        });
      },
      toogleOrderHistoryAndClearFilters: function toogleOrderHistoryAndClearFilters() {
        this.set('showServiceHistory', false);
        this.set('showResourcesHistory', false);
        this.set('searchParams.assetType', '');
        this.set('showOrderHistory', !this.get('showOrderHistory'));
        this.get('searchParams.filters').clear();
      },
      toogleResourcesHistoryAndClearFilters: function toogleResourcesHistoryAndClearFilters() {
        this.set('searchParams.assetType', 'AUDIT_LOG');
        this.set('showResourcesHistory', !this.get('showResourcesHistory'));
        this.get('searchParams.filters').clear();
      },
      loadingResourcesHistoryData: function loadingResourcesHistoryData() {
        this.set('showServiceHistory', false);
        this.set('showOrderHistory', false);
        this.set('loadingInProgress', !this.get('loadingInProgress'));
      }
    }
  });

  _exports.default = _default;
});