define("apollo/pods/vehicle/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    owner: _emberData.default.belongsTo('company', {
      async: true
    }),
    driver: _emberData.default.belongsTo('driver', {
      async: true
    }),
    sideNumber: _emberData.default.attr('string'),
    registrationNumber: _emberData.default.attr('string'),
    resourceDates: _emberData.default.hasMany('resource-date'),
    numberOfUnconfirmedWashingCheckpoints: _emberData.default.attr('number'),
    model: _emberData.default.attr('string'),
    yearOfProduction: _emberData.default.attr('string'),
    capacity: _emberData.default.attr('string'),
    numberOfCompartments: _emberData.default.attr('string'),
    numberOfManholes: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    type: _emberData.default.attr('string'),
    outOfCommission: _emberData.default.attr('boolean'),
    associatedContainer: _emberData.default.belongsTo('container', {
      async: true
    }),
    isExternalResource: _emberData.default.attr('boolean'),
    vehicleType: Ember.computed(function () {
      return this.constructor.modelName.toUpperCase();
    }).readOnly(),
    exactTypeName: Ember.computed(function () {
      return this.get('trailerType') ? this.get('trailerType') : this.get('vehicleType');
    }),
    vehicleNumbers: Ember.computed('', function () {
      return this.get('sideNumber') + ' (' + this.get('registrationNumber') + ')';
    }),
    computedRegistrationNumber: Ember.computed('', function () {
      return this.get('registrationNumber').replace('/', "/\n");
    }),
    findResourceDate: function findResourceDate(type) {
      if (this.get('resourcesDates.length') === 0) {
        return null;
      }

      return this.get('resourceDates').find(function (date) {
        return date.get('controlledDateType.name') === type;
      });
    },
    findControlledDates: function findControlledDates() {
      return this.get('resourceDates');
    }
  });

  _exports.default = _default;
});