define("apollo/pods/components/transport/driver-section/component", ["exports", "apollo/mixins/autocomplete-utils-mixin", "apollo/mixins/input-utils-mixin"], function (_exports, _autocompleteUtilsMixin, _inputUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_autocompleteUtilsMixin.default, _inputUtilsMixin.default, {
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    showTermsOfConditionsAccepted: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('SHOW_TERMS_OF_CONDITIONS_ACCEPTED');
    }),
    nameTermsOfConditionsAccepted: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('NAME_TERMS_OF_CONDITIONS_ACCEPTED');
    }),
    fileTermsOfConditionsAccepted: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('FILE_TERMS_OF_CONDITIONS_ACCEPTED');
    }),
    isSecondDriverChecked: Ember.computed({
      get: function get() {
        if (this._isSecondDriverChecked) {
          return this._isSecondDriverChecked;
        }

        return false;
      },
      set: function set(key, value) {
        return this._isSecondDriverChecked = value;
      }
    }),
    setIsSecondDriverChecked: Ember.on('init', function () {
      this.set('isSecondDriverChecked', !Ember.isEmpty(this.get('transport.advice.additionalDriver.id')));
    }),
    toggleAdditionalDriver: Ember.observer('isSecondDriverChecked', function () {
      var prevAdditionalDriver = this.get('transport.advice.prevAdditionalDriver');

      if (this.get('isSecondDriverChecked') && prevAdditionalDriver) {
        this.get('transport.advice').setProperties({
          prevAdditionalDriver: null,
          additionalDriver: prevAdditionalDriver
        });
      } else if (!this.get('isSecondDriverChecked')) {
        var currentAdditionalDriver = this.get('transport.advice.additionalDriver');
        this.get('transport.advice').setProperties({
          prevAdditionalDriver: currentAdditionalDriver,
          additionalDriver: null
        });
      }
    }),
    handleTruckSelection: Ember.observer('transport.advice.truck', function () {
      if (this.get('transport')) {
        var truck = this.get('transport').get('advice').get('truck');

        if (this.get('transport.advice.truck') && !this.get('transport').get('advice').get('driver.id')) {
          this.get('transport').get('advice').set('driver', truck.get('driver'));
        }

        if (this.get('transport.advice.trailer') && !this.get('transport').get('advice').get('trailer.id')) {
          this.get('transport').get('advice').set('trailer', truck.get('trailer'));
        }
      }
    }),
    setPersonsGroup: Ember.observer('transport.advice.truck', function () {
      if (this.get('transport')) {
        var group = this.get('transport.advice.truck.group');
        this.get('transport').set('personsGroup', group);
      }
    }),
    truckWarnings: Ember.computed('transport.advice.truck.resourceDates.@each.date', 'transport.clientCompany.id', function () {
      return this.findWarningsInAdviceForm('transport.advice.truck.id', 'vehicle');
    }),
    trailerWarnings: Ember.computed('transport.advice.trailer.{id,resourceDates.[]}', 'transport.clientCompany.id', function () {
      return this.findWarningsInAdviceForm('transport.advice.trailer.content.id', 'vehicle');
    }),
    containerWarnings: Ember.computed('transport.advice.trailer.{id,associatedContainer.resourceDates.[]}', 'transport.clientCompany.id', function () {
      return this.findWarningsInAdviceForm('transport.advice.trailer.content.associatedContainer.id', 'vehicle');
    }),
    driverWarnings: Ember.computed('transport.advice.driver.resourceDates.@each.date', 'transport.clientCompany.id', function () {
      return this.findWarningsInAdviceForm('transport.advice.driver.id', 'driver');
    }),
    additionalDriverWarnings: Ember.computed('transport.advice.additionalDriver.resourceDates.@each.date', 'isSecondDriverChecked', 'transport.clientCompany.id', function () {
      if (!this.get('isSecondDriverChecked')) {
        return [];
      }

      return this.findWarningsInAdviceForm('transport.advice.additionalDriver.id', 'driver');
    }),
    findWarningsInAdviceForm: function findWarningsInAdviceForm(propertyPath, resourceName) {
      var _this = this;

      var resourceId = this.get(propertyPath);

      if (!resourceId) {
        return [];
      }

      return this.get('store').peekAll('resourceDate').filter(function (rd) {
        return rd.belongsToResource(resourceId);
      }).filter(function (rd) {
        return rd.applyToClient(_this.get('transport.clientCompany.id'));
      }).getEach('warningInAdviceForm').filter(function (warning) {
        return warning;
      }).sort(this.warningSorter);
    },
    warningSorter: function warningSorter(a, b) {
      return a.date.valueOf() - b.date.valueOf();
    },
    actions: {
      setDriver: function setDriver(driver) {
        this.setItem('transport.advice.driver', 'driver', driver.id);
      },
      setAdditionalDriver: function setAdditionalDriver(driver) {
        this.setItem('transport.advice.additionalDriver', 'driver', driver.id);
      },
      setTruck: function setTruck(truck) {
        this.setItem('transport.advice.truck', 'truck', truck.id);
      },
      setTrailer: function setTrailer(trailer) {
        this.setItem('transport.advice.trailer', trailer.type.toLowerCase(), trailer.id);
      },
      setContainer: function setContainer(container) {
        this.setItem('transport.advice.associatedContainer', 'container', container.id);
      },
      removeTruck: function removeTruck() {
        this.clearItem('transport.advice.truck');
      },
      removeAdditionalDriver: function removeAdditionalDriver() {
        this.clearItem('transport.advice.additionalDriver');
      },
      removeDriver: function removeDriver() {
        this.clearItem('transport.advice.driver');
      },
      removeTrailer: function removeTrailer() {
        this.clearItem('transport.advice.trailer');
      },
      removeContainer: function removeContainer() {
        this.clearItem('transport.advice.associatedContainer');
      }
    }
  });

  _exports.default = _default;
});