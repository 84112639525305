define("apollo/pods/transport/model", ["exports", "jquery", "ember-data", "apollo/mixins/model-validator", "ember-data-copyable"], function (_exports, _jquery, _emberData, _modelValidator, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, {
    intl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    creator: _emberData.default.belongsTo('user', {
      async: true
    }),
    forwarder: _emberData.default.belongsTo('user', {
      async: true
    }),
    clientCompany: _emberData.default.belongsTo('company', {
      async: true
    }),
    principalCompany: _emberData.default.belongsTo('company', {
      async: true
    }),
    carrierCompany: _emberData.default.belongsTo('company', {
      async: true
    }),
    advice: _emberData.default.belongsTo('advice', {
      async: true,
      deepEmbedded: ['creator', 'driver', 'trailer', 'truck', 'additionalDriver', 'associatedContainer']
    }),
    requiredCarType: _emberData.default.belongsTo('carType', {
      async: true
    }),
    firstUnconfirmedCheckpoint: _emberData.default.belongsTo('checkpoint', {
      async: true
    }),
    lastConfirmedCheckpoint: _emberData.default.belongsTo('checkpoint', {
      async: true
    }),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    steps: _emberData.default.hasMany('step', {
      async: true,
      deepEmbedded: ['company', 'address', 'service']
    }),
    checkpoints: _emberData.default.hasMany('checkpoint', {
      async: true
    }),
    additionalData: _emberData.default.hasMany('additionalData', {
      async: true
    }),
    notes: _emberData.default.hasMany('note', {
      async: true
    }),
    files: _emberData.default.hasMany('file', {
      async: true
    }),
    transportLogs: _emberData.default.hasMany('auditLog', {
      async: true
    }),
    controllingIntegrations: _emberData.default.hasMany('controllingIntegration', {
      async: true
    }),
    externalCostIntegrations: _emberData.default.hasMany('externalCostIntegration', {
      async: true
    }),
    transaction: _emberData.default.belongsTo('transaction', {
      async: true,
      deepEmbedded: ['currency', 'currencyOfFreight', 'currencyOfWash']
    }),
    products: _emberData.default.hasMany('product', {
      async: true
    }),
    externalCosts: _emberData.default.hasMany('externalCost', {
      async: true,
      deepEmbedded: ['currency', 'company', 'externalCostType'],
      inverse: 'transport'
    }),
    personsGroup: _emberData.default.belongsTo('userGroup', {
      async: true
    }),
    transportNotifications: _emberData.default.hasMany('transportNotification', {
      async: true
    }),
    controllingIntegrationNumber: _emberData.default.attr('number'),
    internalIndex: _emberData.default.attr('number'),
    erpIdentifier: _emberData.default.attr('string'),
    remoteId: _emberData.default.attr('string'),
    archived: _emberData.default.attr('boolean'),
    dateOfArchiving: _emberData.default.attr('isodate'),
    deleted: _emberData.default.attr('boolean'),
    dateOfDeletion: _emberData.default.attr('isodate'),
    earliestStepStart: _emberData.default.attr('isodate'),
    dateCreated: _emberData.default.attr('date'),
    lastUpdated: _emberData.default.attr('date'),
    description: _emberData.default.attr('string'),
    canBeUpdated: _emberData.default.attr('boolean'),
    canBeDeleted: _emberData.default.attr('boolean'),
    otherRequirements: _emberData.default.attr('string'),
    numberOfUnreadNotes: _emberData.default.attr('number'),
    isFromSap: _emberData.default.attr('boolean'),
    stoNumber: _emberData.default.attr('string'),
    //externalCostOrder
    externalCost: _emberData.default.belongsTo('externalCost', {
      async: true,
      inverse: 'externalCostOrder'
    }),
    transport: _emberData.default.belongsTo('transport', {
      async: true,
      inverse: null
    }),
    orderNumber: _emberData.default.attr('string'),
    pzNumber: _emberData.default.attr('string'),
    plannedDate: _emberData.default.attr('isodate'),
    executionDate: _emberData.default.attr('isodate'),
    isHighlighted: false,
    summaryQuantity: 0,
    summaryQuantityBoxes: 0,
    adviceChecked: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    isGrouping: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),

    /**
     * Generic flag telling that there are some operations/modification in progress.
     */
    operationsInProgress: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    validations: {
      clientCompany: {
        presence: {
          message: 'blank'
        }
      },
      requiredCarType: {
        presence: {
          message: 'blank'
        }
      },
      transaction: {
        relations: ['belongsTo']
      }
    },
    CHECKPOINT_NAMES: {
      arrival: 'ARRIVAL',
      onUnload: 'ON_UNLOAD',
      afterUnload: 'AFTER_UNLOAD',
      departure: 'DEPARTURE'
    },
    TRANSPORT_TYPES: {
      transport: 'transport',
      service: 'service'
    },
    isTransport: Ember.computed('transportType', function () {
      return this.get('transportType.type') === this.get('TRANSPORT_TYPES').transport;
    }),
    STATE_INITIAL_NAME: Ember.computed(function () {
      return this.get('intl').t('statuses.initial');
    }),
    STATE_DELETED_NAME: Ember.computed(function () {
      return this.get('intl').t('statuses.deleted').toString();
    }),
    STATE_FINISHED_NAME: Ember.computed(function () {
      return this.get('intl').t('statuses.finished').toString();
    }),
    name: Ember.computed('internalIndex', function () {
      return this.get('internalIndex') ? 'Transport nr ' + this.get('internalIndex') : 'Nowy transport';
    }),
    isLate: Ember.computed('firstUnconfirmedCheckpoint.id', 'firstUnconfirmedCheckpoint.hasAlert', function () {
      return this.get('firstUnconfirmedCheckpoint.hasAlert');
    }),
    isLeave: Ember.computed(function () {
      return this.get('transportType.name') === 'LEAVE';
    }),
    isService: Ember.computed(function () {
      return this.get('transportType.name') === 'SERVICE';
    }),
    color: Ember.computed('lastUpdated', 'deleted', 'isLate', 'firstUnconfirmedCheckpoint.task.color', function () {
      if (this.get('isLeave')) {
        return "#9aa6bc";
      }

      if (this.get('deleted')) {
        return "darkgray"; // TODO: [pawel] umożliwić użytkownikowi ustalenie tego koloru w ustawieniach
      }

      if (this.get('archived') || this.get('isCompleted')) {
        return "dimgray"; // TODO: [pawel] umożliwić użytkownikowi ustalenie tego koloru w ustawieniach
      }

      if (this.get('isLate')) {
        return this.get('firstUnconfirmedCheckpoint.id') ? this.get('firstUnconfirmedCheckpoint.task.lateColor') : "red";
      }

      return this.get('firstUnconfirmedCheckpoint.id') ? this.get('firstUnconfirmedCheckpoint.task.color') : null;
    }),
    backgroundColorStyle: Ember.computed('color', function () {
      return Ember.String.htmlSafe("background-color: " + this.get('color') + ";");
    }),
    textColorStyle: Ember.computed('color', function () {
      return Ember.String.htmlSafe("color: " + this.get('color') + ";");
    }),
    isActive: Ember.computed('isCompleted', 'archived', 'deleted', function () {
      return !(this.get('isCompleted') || this.get('deleted') || this.get('archived'));
    }),
    isCompleted: Ember.computed('firstUnconfirmedCheckpoint.id', function () {
      return this.get('id') && !this.get('firstUnconfirmedCheckpoint.id');
    }),
    stageName: Ember.computed('firstUnconfirmedCheckpoint.stageName', function () {
      if (this.get("deleted")) {
        return this.get('STATE_DELETED_NAME');
      } else if (this.get("archived") || this.get('isCompleted')) {
        return this.get('STATE_FINISHED_NAME');
      }

      var point = this.get('firstUnconfirmedCheckpoint');
      return point ? point.get('stageName') : this.get('STATE_INITIAL_NAME');
    }),
    stageNameAndKey: Ember.computed('firstUnconfirmedCheckpoint.stageName', function () {
      if (this.get("deleted")) {
        return {
          name: this.get('STATE_DELETED_NAME'),
          key: 'DELETED'
        };
      } else if (this.get("archived") || this.get('isCompleted')) {
        return {
          name: this.get('STATE_FINISHED_NAME'),
          key: 'COMPLETED'
        };
      }

      var point = this.get('firstUnconfirmedCheckpoint');
      return {
        name: point.get('stageName'),
        key: point.get('task.stageNameEn')
      };
    }),
    sortProperty: Ember.computed('id', function () {
      return this.get('id');
    }),
    setSummaryQuantity: Ember.on('init', Ember.observer('steps.@each.quantity', function () {
      var _this = this;

      var q = 0;
      this.get('steps').then(function (steps) {
        steps.forEach(function (s) {
          if (s) {
            q += parseFloat(s.get('quantity')) || 0;
          }
        });

        _this.set('summaryQuantity', q);

        if (q) {
          _this.get('errors').remove('summaryQuantity');

          _this.get('errors').remove('summaryQuantityBoxes');
        }
      });
    })),
    setSummaryQuantityBoxes: Ember.observer('steps.@each.quantityBoxes', function () {
      var _this2 = this;

      var q = 0;
      this.get('steps').then(function (steps) {
        steps.forEach(function (s) {
          if (s) {
            q += parseFloat(s.get('quantityBoxes')) || 0;
          }
        });

        _this2.set('summaryQuantityBoxes', q);
      });

      if (this.get('summaryQuantity')) {
        this.get('errors').remove('summaryQuantityBoxes');
      }
    }),
    service: Ember.computed('steps.firstObject.service', function () {
      return this.get('steps.firstObject.service');
    }),
    leaveStep: Ember.computed('steps.@each.firstObject', function () {
      return this.get('steps').filterBy('stepTypeName', 'leave').get('firstObject');
    }),
    firstStep: Ember.computed('steps.@each.id', 'steps.@each.orderInTransport', function () {
      return this.get('sortedSteps').get('firstObject');
    }),
    firstAddress: Ember.computed('firstStep.address', function () {
      return this.get('firstStep').get('address');
    }),
    firstCountry: Ember.computed('firstAddress.country', function () {
      return this.get('firstAddress').get('country');
    }),
    firstIbanSymbol: Ember.computed('firstCountry.ibanSymbol', function () {
      return this.get('firstCountry.ibanSymbol');
    }),
    lastStep: Ember.computed('steps.@each.id', 'steps.@each.orderInTransport', function () {
      return this.get('sortedSteps').get('lastObject');
    }),
    lastAddress: Ember.computed('lastStep.address', function () {
      return this.get('lastStep').get('address');
    }),
    lastCountry: Ember.computed('lastAddress.country', function () {
      return this.get('lastAddress').get('country');
    }),
    lastIbanSymbol: Ember.computed('lastCountry.ibanSymbol', function () {
      return this.get('lastCountry.ibanSymbol');
    }),
    firstContractor: Ember.computed('contractors.length', function () {
      return this.get('contractors').get('firstObject');
    }),
    supplierNames: Ember.computed('contractors.@each.company', function () {
      return this.get('contractors').getEach('name').filter(function (s) {
        return s !== undefined;
      });
    }),
    loadSteps: Ember.computed('steps.@each', function () {
      return this.get('steps').filterBy('stepTypeName', 'load');
    }),
    unloadSteps: Ember.computed('steps.@each', function () {
      return this.get('steps').filterBy('stepTypeName', 'unload');
    }),
    washStep: Ember.computed('steps.@each', function () {
      return this.get('steps').filterBy('stepTypeName', 'wash').get('firstObject');
    }),
    washCost: Ember.computed('externalCosts.[]', function () {
      return this.get('externalCosts').find(function (c) {
        return c.get('externalCostType.name') === 'AUTOMATIC_WASHING';
      });
    }),
    wash: Ember.computed('washStep', function () {
      return this.get('washStep.company');
    }),
    sortedSteps: Ember.computed('steps.@each', 'steps.@each.orderInTransport', function () {
      return this.get('steps').sortBy('orderInTransport');
    }),
    sortedLoadSteps: Ember.computed('loadSteps.@each.orderInTransport', function () {
      return this.get('loadSteps').sortBy('orderInTransport');
    }),
    sortedUnloadSteps: Ember.computed('unloadSteps.@each.orderInTransport', function () {
      return this.get('unloadSteps').sortBy('orderInTransport');
    }),
    resourceId: Ember.computed('advice', 'carrierCompany', 'advice.truck', 'advice.driver', 'personsGroup', function () {
      if (!this.get('personsGroup.id') && !this.get('carrierCompany.id') && !this.get('isLeave')) {
        return 'NOTASSIGNED';
      } else if (this.get('personsGroup.id') && !this.get('carrierCompany.id') && !this.get('isLeave')) {
        return 'NOTASSIGNED-' + this.get('personsGroup.id');
      }

      if (!this.get('isLeave') && !this.get('isService') || this.get('isService') && this.get('transportTypeParam') === 'TRANSPORT') {
        var resourceId = this.get('personsGroup.id');

        if (this.get('carrierCompany.id')) {
          if (this.get('carrierCompany.isExternal')) {
            return this.get('carrierCompany.id');
          } else {
            var companyIds = this.get('personsGroup.companiesWithTrucksInGroup').map(function (c) {
              return c.get('id');
            });

            if (companyIds.includes(this.get('carrierCompany.id'))) {
              if (this.get('advice.truck') && this.get('advice.truck.id') && this.get('advice.truck.owner')) {
                resourceId += '-' + this.get('advice.truck.owner.id');
              } else if (this.get('advice.trailer') && this.get('advice.trailer.id') && this.get('advice.trailer.truck.owner')) {
                resourceId += '-' + this.get('advice.trailer.truck.owner.id');
              } else {
                resourceId += '-' + this.get('carrierCompany.id');
              }
            }

            if (this.get('advice.truck') && this.get('advice.truck.id') && this.get('advice.truck.group.id') === this.get('personsGroup.id')) {
              resourceId += '-' + this.get('advice.truck.id');
            }

            if (!this.get('advice.truck.id') && this.get('advice.trailer.id') && this.get('advice.trailer.truck.group.id') === this.get('personsGroup.id')) {
              resourceId += '-' + this.get('advice.trailer.truck.id');
            }
          }
        } else {
          resourceId += '-NO_CARRIER';
        }

        return resourceId;
      } else if (this.get('isService') && this.get('transportTypeParam') === 'SERVICE') {
        var company = this.get('steps.firstObject.company');
        var mechanic = this.get('steps.firstObject.service.mechanic');

        if (company && company.get('isExternal') && company.get('id')) {
          return 'PLANNED-' + this.get('steps.firstObject.company.id');
        } else if (company && !company.get('id')) {
          return 'NOTPLANNED';
        } else if (company && company.get('id') && !company.get('isExternal') && mechanic && !mechanic.get('id')) {
          return 'NOTASSIGNED-' + company.get('id');
        } else if (company && !company.get('isExternal') && company.get('id') && mechanic && mechanic.get('id')) {
          return company.get('id') + '-' + mechanic.get('id');
        }
      } else if (this.get('isLeave') && (this.get('advice.driver.company.isPrincipal') || !this.get('advice.driver.company.isExternal'))) {
        var _resourceId = this.get('advice.driver.truck.group.id');

        var driverCompany = this.get('advice.driver.company');

        if (driverCompany.get('id')) {
          _resourceId += '-' + driverCompany.get('id') + '-' + this.get('advice.driver.truck.id');
        }

        return _resourceId;
      }
    }),
    eventDates: Ember.computed('asScheduleEvent', function () {
      var start = new Date(this.get('steps.firstObject.deliveryDateFrom'));
      var deliveryDateTo = new Date(this.get('steps.lastObject.deliveryDateTo'));
      var deliveryDateFrom = new Date(this.get('steps.lastObject.deliveryDateFrom'));
      var end = new Date();

      if (deliveryDateTo > deliveryDateFrom) {
        end = new Date(this.get('steps.lastObject.deliveryDateTo'));
      } else {
        end = new Date(this.get('steps.lastObject.deliveryDateFrom'));
      }

      return {
        start: start,
        end: end
      };
    }),
    asScheduleEvent: Ember.computed('schedulerEventTitle', 'resourceId', 'deleted', 'archived', 'steps.@each', 'color', 'isCompleted', function () {
      var start = this.get('eventDates.start');
      var end = moment();

      if (!this.get('isLeaveForPrincipalWorker')) {
        end = moment(this.get('eventDates.end'));
      } else if (this.get('isLeaveForPrincipalWorker')) {
        end = moment(this.get('eventDates.end')).add(24, 'h');
      }

      return {
        title: this.get('schedulerEventTitle'),
        start: start,
        end: end,
        resourceId: this.get('resourceId'),
        overlap: true,
        color: this.get('color'),
        transportId: this.get('id'),
        editable: !(this.get('deleted') || this.get('archived') || this.get('isCompleted')) && !this.get('isWindowEditionBlocked'),
        durationEditable: false
      };
    }).readOnly(),
    isWindowEditionBlocked: Ember.computed('sortedCheckpoints.@each.executionDate', 'sortedCheckpoints.@each.id', function () {
      var confirmedCheckpoints = this.get('sortedCheckpoints').filterBy('executionDate');
      var filteredCheckpoints = confirmedCheckpoints.filter(function (c) {
        return c.get('task.blocksWindowEdition');
      });
      return filteredCheckpoints.length > 0;
    }),
    isLeaveForPrincipalWorker: Ember.computed(function () {
      return this.get('isLeave') && this.get('resourceId') && (!this.get('carrier.company.isExternal') || this.get('carrier.company.isPrincipal'));
    }),
    showFilesDiv: Ember.computed('browserOld', function () {
      return this.get("files.length") || !this.get("browserOld");
    }),
    browserOld: Ember.computed(function () {
      return (0, _jquery.default)('html').is('.old');
    }),
    canBeAccessedByCompany: function canBeAccessedByCompany(company) {
      if (this.get('creator.company.id') === company.get('id') || this.get('carrierCompany').get('id') === company.get('id')) {
        return true;
      }

      return this.belongsToContractors(company);
    },
    belongsToContractors: function belongsToContractors(company) {
      return !!this.get('contractors').findBy('id', company.get('id'));
    },
    canConfirmArrival: Ember.computed('arrivalCheckpoint.isConfirmed', function () {
      var arrivalCheckpoint = this.get('arrivalCheckpoint');
      return arrivalCheckpoint && !arrivalCheckpoint.get('isConfirmed');
    }),
    canConfirmDeparture: Ember.computed('arrivalCheckpoint.isConfirmed', 'departureCheckpoint.isConfirmed', function () {
      var departureCheckpoint = this.get('departureCheckpoint');

      if (this.get('isTransport')) {
        var onUnloadCheckpoint = this.get('onUnloadCheckpoint');
        return onUnloadCheckpoint && departureCheckpoint && onUnloadCheckpoint.get('isConfirmed') && !departureCheckpoint.get('isConfirmed');
      } else {
        var arrivalCheckpoint = this.get('arrivalCheckpoint');
        return arrivalCheckpoint && departureCheckpoint && arrivalCheckpoint.get('isConfirmed') && !departureCheckpoint.get('isConfirmed');
      }
    }),
    arrivalCheckpoint: Ember.computed('checkpoints.@each.id', 'checkpoints.@each.expectedDate', function () {
      return this.findCheckpoint(this.get('CHECKPOINT_NAMES').arrival);
    }),
    departureCheckpoint: Ember.computed('checkpoints.@each.id', 'checkpoints.@each.expectedDate', function () {
      return this.findCheckpoint(this.get('CHECKPOINT_NAMES').departure);
    }),
    onUnloadCheckpoint: Ember.computed('checkpoints.@each.id', 'checkpoints.@each.expectedDate', function () {
      return this.findCheckpoint(this.get('CHECKPOINT_NAMES').onUnload);
    }),
    findCheckpoint: function findCheckpoint(name) {
      return this.get('checkpoints').filterBy('checkpointName', name).get('firstObject');
    },
    title: Ember.computed('transportType.label', function () {
      return this.get('transportType').get('label');
    }).readOnly(),
    canNotBeEdited: Ember.computed('isActive', 'canBeUpdated', function () {
      if (!this.get('isActive')) {
        return true;
      }

      var transportLevelBlocade;

      if (this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_TRANSPORT')) {
        transportLevelBlocade = false;
      } else {
        transportLevelBlocade = this.get('id') && !this.get('canBeUpdated');
      }

      return transportLevelBlocade;
    }),
    isEditable: Ember.computed('canNotBeEdited', function () {
      return !this.get('canNotBeEdited') || !this.get('remoteId');
    }),
    earliestLoadDate: Ember.computed('earliestStepStart', function () {
      return this.get('earliestStepStart') ? moment(this.get('earliestStepStart')).startOf('day').toDate() : null;
    }),
    isConfirmed: Ember.computed('checkpoints.@each.isConfirmed', function () {
      var s = this.get('store').peekAll('setting').filterBy('name', 'CHECKPOINT_THAT_APPROVES_ORDER');
      var checkpointName = s.get('firstObject').get('value');
      return this.findCheckpoint(checkpointName) ? this.findCheckpoint(checkpointName).get('isConfirmed') : null;
    }),
    washIsConfirmed: Ember.computed('checkpoints.@each.isConfirmed', function () {
      var washCheckpoint = this.get('checkpoints').find(function (c) {
        return c.get('task.stepType') === 'wash';
      });

      if (!washCheckpoint) {
        return false;
      }

      return washCheckpoint.get('isConfirmed');
    }),
    hasUnreadNotes: Ember.computed('numberOfUnreadNotes', function () {
      return this.get('numberOfUnreadNotes') > 0;
    }),
    numberOfNotes: Ember.computed('notes', 'numberOfUnreadNotes', function () {
      return this.hasMany('notes').ids().length;
    }),
    numberOfFiles: Ember.computed('files.@each.id', function () {
      return this.hasMany('files').ids().length;
    }),
    canHaveAdviceAdded: Ember.computed('archived', 'deleted', 'advice.id', 'carrierCompany.id', 'transportType.canHaveAdviceAddedByRoleGroup', function () {
      if (this.get('archived') || this.get('deleted') || this.get('advice.id')) {
        return false;
      }

      return this.get('carrierCompany.id') && this.get('transportType.canHaveAdviceAddedByRoleGroup');
    }),
    schedulerEventTitle: Ember.computed('steps.@each', 'productsSymbols', 'advice.truck.registrationNumber', 'clientCompany.shortName', 'steps.firstObject.service.requestedJobs', 'steps.firstObject.service.finishedJobs', function () {
      var _this3 = this;

      if (!this.get('isLeave') && !this.get('isService')) {
        var address = '';
        var registrationNumberPrefix = '';
        this.get('steps').filterBy('isWash', false).forEach(function (step) {
          if (_this3.get('carrierCompany.id') && _this3.get('advice.id') && _this3.get('carrierCompany.isExternal')) {
            address = step.get('address.country.ibanSymbol') + ' - ' + step.get('address.city') + ' ' + step.get('address.country.ibanSymbol') + ' - ' + step.get('address.city');
            registrationNumberPrefix = _this3.get('advice.truck.registrationNumber') + ' ';
          } else {
            address += ' ' + step.get('address.country.ibanSymbol') + ' - ' + step.get('address.city');
          }
        });
        return registrationNumberPrefix + this.get('clientCompany.shortName') + ' ' + address + ' ' + this.get('productsSymbols');
      } else if (this.get('isService')) {
        var description = this.get('steps.firstObject.service.requestedJobs');

        if (this.get('advice.truck.sideNumber')) {
          return description ? this.get('advice.truck.sideNumber') + '-' + description : this.get('advice.truck.sideNumber') + '-' + this.get('intl').t('service.noJobs');
        }

        if (this.get('advice.truck.registrationNumber')) {
          return description ? this.get('advice.truck.registrationNumber') + '-' + description : this.get('advice.truck.registrationNumber') + '-' + this.get('intl').t('service.noJobs');
        }

        if (this.get('advice.trailer.sideNumber')) {
          return description ? this.get('advice.trailer.sideNumber') + '-' + description : this.get('advice.trailer.sideNumber') + '-' + this.get('intl').t('service.noJobs');
        }

        if (this.get('advice.trailer.registrationNumber')) {
          return description ? this.get('advice.trailer.registrationNumber') + '-' + description : this.get('advice.trailer.registrationNumber') + '-' + this.get('intl').t('service.noJobs');
        }
      } else {
        return "".concat(this.get('advice.driver.name'), " - ").concat(this.get('intl').t('transport.type.LEAVE'));
      }
    }),
    productsSymbols: Ember.computed('products.@each', function () {
      var products = '';
      this.get('products').forEach(function (product) {
        products += product.get('symbol') + ' ';
      });
      return products;
    }),
    cancelAdvice: function cancelAdvice() {
      console.debug("Cancelling advice for ".concat(this.get('advice.transport.id'), ".."));

      if (this.get('advice.id')) {
        this.set('adviceChecked', false);
        this.set('oldAdvice', this.get('advice'));
        this.set('advice', null);
      }
    },
    setAdvice: function setAdvice(truck) {
      this.set('adviceChecked', true);
      var advice;

      if (this.get('advice.id')) {
        advice = this.get('advice');
      } else {
        advice = this.get('store').createRecord('advice', {
          transport: this
        });
      }

      advice.set('truck', truck);

      if (truck !== null) {
        this.set('carrierCompany', truck.get('owner'));

        if (truck.get('trailer.id')) {
          advice.set('trailer', truck.get('trailer'));
        } else {
          advice.set('trailer', null);
        }

        if (truck.get('driver.id')) {
          advice.set('driver', truck.get('driver'));
        }
      }
    },
    cancelCarrier: function cancelCarrier() {
      this.set('carrierCompany', null);
      this.get('transaction.id') ? this.set('transaction.priceOfFreight', null) : null;
      this.get('transaction.id') ? this.set('transaction.currencyOfFreight', null) : null;
    },
    cancelGroup: function cancelGroup() {
      this.set('isGrouping', true);
      this.set('personsGroup', null);
    },
    sortedCheckpoints: Ember.computed('checkpoints.@each.idx', function () {
      return this.get('checkpoints').sortBy('idx');
    }).readOnly(),
    sortedActiveFiles: Ember.computed('files.@each.dateCreated', 'files.@each.active', function () {
      return this.get('files').filterBy('active').sortBy('dateCreated');
    }).readOnly(),
    refresh: function refresh() {
      try {
        this.get('transportType').set('forcedPollRequestTime', new Date().getTime());
      } catch (e) {
        console.error(e);
      }
    },
    activeNotifications: Ember.computed('transportNotifications.@each.active', function () {
      return this.get('transportNotifications').filterBy('active').sortBy('notification.idx');
    }),
    notificationsVisibleInTransportTable: Ember.computed('transportNotifications.@each.active', function () {
      return this.get('transportNotifications').filterBy('notification.visibleInTransportTable', true).filterBy('active').sortBy('notification.idx');
    }),
    visibleInTransportSchedule: Ember.computed('transportType', 'carrierCompany', 'advice.trailer', 'advice.truck', function () {
      // nie wyświetlamy serwisów dla przewoźników zewnętrznych w harmonogramie transportów
      var isExternalService = this.get('transportType.name') === 'SERVICE' && this.get('carrierCompany.isExternal'); // nie wyświetlamy serwisów dla naczep, które nie są przypisane do ciągników

      var isUnassignedTrucksService = this.get('transportType.name') === 'SERVICE' && this.get('advice.trailer.id') && !this.get('advice.trailer.truck.id');
      return !isExternalService && !isUnassignedTrucksService;
    }),
    shortDescription: Ember.computed('description', function () {
      var shortDescriptionLength = 40;
      return this.get('description.length') > shortDescriptionLength ? this.get('description').substring(0, shortDescriptionLength) + '...' : this.get('description');
    }),
    isImport: Ember.computed('unloadSteps.[]', 'loadSteps.[]', function () {
      var loadIbanSymbols = this.get('loadSteps').getEach('address.country.ibanSymbol');
      var unloadIbanSymbols = this.get('unloadSteps').getEach('address.country.ibanSymbol');
      return loadIbanSymbols.some(function (iban) {
        return iban !== 'PL' && iban !== 'CZ';
      }) && unloadIbanSymbols.some(function (iban) {
        return iban === 'PL' || iban === 'CZ';
      });
    }),
    isExport: Ember.computed('unloadSteps.[]', 'loadSteps.[]', function () {
      var loadIbanSymbols = this.get('loadSteps').getEach('address.country.ibanSymbol');
      var unloadIbanSymbols = this.get('unloadSteps').getEach('address.country.ibanSymbol');
      return loadIbanSymbols.some(function (iban) {
        return iban === 'PL' || iban === 'CZ';
      }) && unloadIbanSymbols.some(function (iban) {
        return iban !== 'PL' && iban !== 'CZ';
      });
    }),
    restoreData: function restoreData() {
      var _this4 = this;

      if (this.get('oldAdvice') && this.get('oldAdvice.id')) {
        this.set('advice', this.get('oldAdvice'));
        this.set('oldAdvice', null);
      }

      this.rollbackAttributes();
      this.get('advice').then(function (a) {
        if (a) {
          a.rollbackAttributes();
        }
      });
      this.get('forwarder').then(function (f) {
        if (f) {
          f.rollbackAttributes();
        }
      });
      this.get('transaction').then(function (t) {
        if (t && t.get('currency')) {
          t.get('currency').then(function (c) {
            c.rollbackAttributes();
          });
        }
      });

      if (this.get('carrierCompany.name')) {
        this.get('carrierCompany').then(function (c) {
          if (c.id) {
            c.rollbackAttributes();
          } else {
            c.unloadRecord();

            if (_this4.get('oldCarrierCompany')) {
              console.log(_this4.get('oldCarrierCompany'));

              _this4.setProperties({
                company: _this4.get('oldCarrierCompany'),
                oldCarrierCompany: null
              });
            }
          }
        });
      }

      this.get('steps').then(function (steps) {
        steps.forEach(function (s) {
          if (s === undefined) {
            return true;
          }

          s.rollbackAttributes();

          if (!s.get('company.name')) {
            return true;
          }

          s.get('company').then(function (comp) {
            if (comp.get('id')) {
              comp.rollbackAttributes();
            } else {
              comp.unloadRecord();

              if (s.get('oldCompany')) {
                s.setProperties({
                  company: s.get('oldCompany'),
                  oldCompany: null
                });
              }
            }
          });
        });
      });
      this.get('products').then(function (products) {
        products.forEach(function (product) {
          if (product.get('id')) {
            product.rollbackAttributes();
          } else {
            product.unloadRecord();
          }
        });
      });
      this.get('externalCosts').then(function (externalCosts) {
        externalCosts.forEach(function (ec) {
          if (ec.get('id')) {
            ec.rollbackAttributes();
          } else {
            ec.unloadRecord();
          }
        });
      });

      if (this.get('id')) {
        this.reload();
      }
    },
    logString: Ember.computed('id', 'internalIndex', 'erpIdentifier', function () {
      return "#".concat(this.get('id'), " / ").concat(this.get('internalIndex'), " / ERP ").concat(this.get('erpIdentifier'));
    }),
    freightCost: Ember.computed('externalCosts.@each', function () {
      return this.get('externalCosts').find(function (cost) {
        return cost.get('externalCostType.name') === 'FREIGHT';
      });
    }),
    deletingOrderNotPossibleHint: Ember.computed('deleted', 'pzNumber', function () {
      if (this.get('deleted')) {
        return this.get('intl').t('external-cost.order.deleted');
      } else if (!this.get('sessionAccount').hasRole('ROLE_CAN_DELETE_EXTERNAL_COST') && !this.get('archived')) {
        return this.get('intl').t('external-cost.order.noAuthorityToDelete');
      } else if (this.get('pzNumber') && !this.get('archived')) {
        return this.get('intl').t('external-cost.order.deletingNotPossibleConfirmedInSap');
      } else {
        return null;
      }
    }),
    washSentToSap: Ember.computed('externalCosts.@each.externalCostIntegrationNumber', function () {
      var washCost = this.get('externalCosts').find(function (ec) {
        return ec.get('externalCostType.name') === 'AUTOMATIC_WASHING';
      });

      if (!washCost) {
        return false;
      }

      return washCost.get('externalCostIntegrationNumber');
    })
  });

  _exports.default = _default;
});